.DialogParent > div {
    background-color: rgba(0, 0, 0, 0.96) !important;
    z-index: 9999999999 !important;
  }
  .DialogParent {
    background-color: black;
  }
  
  .window {
    width: 100vw;
    height: 100vh;
    background-color: #202020;
    padding: 0rem;
    background: url(../../../assets/images/back_top.png) no-repeat,
      #202020 url(../../../assets/images/back_footer.png) no-repeat;
    background-position: top left -20px, bottom -110px right -135px;
    background-size: 400px, 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .window > div {
    background: none !important;
  }
  
  .content p {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    color: white;
    margin: 0px;
    text-align: start;
  }
  .windowBtns {
    background: none !important;
    border-color: white !important;
    border-radius: 0px !important;
    width: 100% !important;
    margin: 0 0.5rem 0.5rem 0 !important;
  }
  .windowBtns span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 1.25rem;
    line-height: 44px;
    padding: 0 8px;
  }
  
  @media screen and (min-width: 576px) {
    .window {
      height: auto;
    }
    .windowBtns {
      width: auto !important;
    }
  }
  @media screen and (min-width: 768px) {
    .window {
      width: 70vw;
      height: auto;
      background-color: #202020;
      padding: 0.25rem;
      background-position: top left -20px, bottom -110px right -135px;
      background-size: 400px, 350px;
    }
    .content p {
      font-size: 56px;
    }
  }
  
  @media screen and (min-width: 992px) {
    .content p {
      font-size: 70px;
    }
    .windowBtns span {
      font-size: 1.75rem;
      padding: 0 12px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .window {
      width: 50vw;
    }
  }