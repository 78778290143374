.p-inputtext {
  background: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #495057 !important;
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
}
.p-inputwrapper-focus > .p-inputtext:enabled:focus,
.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: #495057 !important;
}
.p-inputtext:enabled:hover {
  border-color: #495057 !important;
}
.c-label {
  font-family: MontserratBlack;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: initial;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border-color: #ffcc75 !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-highlight {
  /* background: #ffffff !important; */
  border-color: #ffa307 !important;
  padding: 0.1rem;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px !important;
  height: 12px !important;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #ffa307 !important;
}

.p-rating .p-rating-icon.pi-star-fill {
  color: #f7a309 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  background: #f3f2f1 !important;
  border-color: #f3f2f1 !important;
  color: #a19f9d !important;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  transform: scale(1.1) !important;
}