.header{
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
}

.header_link {
  font-size: 14px;
  font-family: var(--font-family-regular);
  color: black;
}

@media screen and (min-width: 768px) {
  .header{
    border-radius: 10px;
    padding: 12px 24px;
    height: 71px;
  }
  .header{
    box-shadow: 0 4px 4px #00000047;
  }
}