.App {
  text-align: center;
  max-width: 1600px;
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-page {
  min-height: calc(100vh - 200px);
}

.p-scrollpanel.custombar .p-scrollpanel-bar {
  background-color: var(--surface-d);
  border-radius: 0;
  opacity: 1;
  transition: background-color .2s;
  width: 4px;
}

.p-scrollpanel.custombar .p-scrollpanel-content{
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  white-space: pre-wrap;
}

.p-dataview-emptymessage{
  padding: 1rem !important;
}

.custom-button-14px {
  padding: 0.563rem !important; 
  font-size: 14px !important;
}

.p-tabview .p-tabview-nav li {
  margin-right: 2px !important;
  border: 1px solid rgb(76 175 80);
  border-radius: 5px 5px 0px 0px;
}

.tabs-header-red a{
  background-color: rgb(195, 52, 52) !important;
}

.tabs-header-red span{
  color: white;
}

.tabs-header-inactive, .tabs-header-red{
  background-color: rgb(222 226 230) !important;
  border: 1px solid rgb(222 226 230) !important;
}
.tabs-header-inactive a{
  background: none !important;
}

.full-window{
  
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-bottom: 0 none;
}

.p-panelmenu .p-panelmenu-header > a {
  border: none !important;
}

.p-sidebar .p-sidebar-header + .p-sidebar-content{
  padding-left: 0rem;
  padding-right: 0rem;
}

.p-sidebar-header{
  padding-left: .25rem;
  padding-right: .25rem;
}

.p-panelmenu-icon{
  order: 1;
  margin-left: auto;
}

.my-establishment {
  color: #3B8F12;
  font-family: 'MontserratBold';
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.p-picklist-buttons > .p-button {
  background: #ffa307 !important;
  border: 1px solid #ffa307 !important;
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
  box-shadow: inset 0 0 0 0.15rem #fbc02d !important;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  background: #fff8e6 !important;
}

.card{
  background: var(--surface-e);
  padding: 2rem 1rem;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 4px;
  margin-bottom: 1rem;
}

.card-mini{
  background: var(--surface-e);
  padding: 1rem;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 4px;
  margin-bottom: 1rem;
}

.p-datatable-tbody{
  font-family: 'MontserratRegular';
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 1rem !important;
  background: #fff !important;
  font-size: 14px;
  border-width: 1px 0px 1px 0px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 1rem !important;
  font-size: 14px !important;
}

.sides-max{
  /* max-width: 1680px; */
}

.logotype{
  width: 103px;
  height: 54px;
}
.h-100 {
  height: 100%;
}

.color-green {
  color: #30b003;
}
.color-red {
  color: #e20000;
}

.color-white {
  color: white !important;
}

.c-bg-white {
  background-color: white;
}

.c-ts-black {
  text-shadow: 1px 0px 0px black, 0px 0px 8px white;
}

.bb-color-black-1 {
  border-bottom: 1px solid #1f1f1f2b;
}

.App > .sides-border {
  padding-left: 8px;
  padding-right: 8px;
}

.App {
  text-align: center;
  background-repeat: no-repeat;
  background-position: calc(0% - 132px) 187px, calc(100% + 33px) 65px;
  background-size: 243px, 117px;
  /* background-attachment: fixed; */
}

.c-h-100 {
  height: 100px;
}
.c-vh-100 {
  height: 100vh;
}
.text-purple {
  color: #6d5fa5;
}
.bb-color-purple {
  border-bottom: 5px solid #6d5fa5;
}
.text-strawberry {
  color: #ff515b;
}
.bb-color-strawberry {
  border-bottom: 5px solid #ff515b;
}
.text-magenta {
  color: #18e9ed;
}
.bb-color-magenta {
  border-bottom: 5px solid #18e9ed;
}

.line-vertical {
  border: 3px solid black;
}

.filters label {
  font-size: 14px;
  text-align: initial;
}
.input-b-border input {
  border-width: 0px 0px 1px;
}
.input-b-border textarea {
  border-width: 0px 0px 1px;
}

.input-b-border input:enabled:focus,
.input-b-border textarea:enabled:focus {
  box-shadow: none;
}

.c-main a {
  text-decoration: none;
}

.header {
  height: 75px;
  margin-bottom: 28px;
}
.header-geolocation {
  padding-left: 5% !important;
}
.header-logo {
  width: 85px;
  padding: 21px 8px;
}

.sidebar {
  opacity: 0;
  position: sticky;
  top: 110px;
  transition: 0.5s;
}
.sidebar-show {
  opacity: 1;
  top: 10px;
  transition: 0.3s;
  z-index: 10;
}
.side-panel {
  padding: 8px;
}
.side-panel-mob {
  width: 35px !important;
  padding: 8px;
}
.side-panel-navLink {
  width: 100px;
  margin: 8px 0px;
}
.side-panel-mobile {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  flex-direction: row-reverse;
  /* margin: 0 5px; */
  position: sticky;
  top: 10px;
}
.content-mobile {
  width: 100%;
}
.side-panel-mobile-soc {
  position: sticky;
  top: 300px;
}
.side-panel-mobile span {
  font-size: 16px;
}
.side-panel-navSoc a {
  margin-right: 8px;
  font-size: 28px;
}
.side-panel-geolocation span {
  font-size: 12px;
}

.horizontal-banners {
  padding: 0.5rem;
}
.side-panel-banners div,
.horizontal-banners div {
  width: 100%;
  background-position: center !important;
  display: block;
}
.side-panel-banners div {
  height: calc(20vw - 120px);
  min-height: 170px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.horizontal-banners div {
  height: 103px;
  /* background-color: #0f0f0f !important; */
  /* background-color: rgb(255, 255, 255) !important; */
  border: 1px solid rgb(255, 255, 255);
  /* box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15); */
  /* border-radius: 5px; */
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.btn-now.p-togglebutton.p-button:focus,
.btn-later.p-togglebutton.p-button:focus {
  box-shadow: none;
}
.btn-now.p-button.p-button-outlined {
  /* border: 1px solid #ed0000;*/
  border: none;
  border-radius: 0;
  text-align: left;
}
.btn-later.p-button.p-button-outlined {
  /* border: 1px solid #000; */
  border: none;
  border-radius: 0;
  text-align: left;
}
.btn-now.p-togglebutton.p-button .p-button-icon-left,
.btn-now.p-button.p-button-outlined .p-button-label {
  color: black;
}
.btn-later.p-togglebutton.p-button .p-button-icon-left,
.btn-later.p-button.p-button-outlined .p-button-label {
  color: #000;
}
.btn-now.p-button.p-button-outlined:hover .p-button-label,
.btn-later.p-button.p-button-outlined:hover .p-button-label {
  color: #495057;
}
.btn-now.p-togglebutton.p-button.p-highlight:hover {
  background: none;
  border-color: #000;
  color: #ed0000;
}
.btn-later.p-togglebutton.p-button.p-highlight:hover,
.btn-now.p-togglebutton.p-button.p-highlight:hover {
  background: none;
  border-color: #000;
  color: #000;
}
.btn-later.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover,
.btn-now.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: none;
  border-color: #000;
  color: #000;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  border-color: none !important;
}
.btn-now.p-button.p-button-outlined.p-highlight .p-button-label,
.btn-later.p-button.p-button-outlined.p-highlight .p-button-label {
  color: #ed0000;
}
.btn-now.p-togglebutton.p-button.p-highlight {
  background: none;
  border-color: #ed0000;
  color: #ed0000 !important;
}
.btn-later.p-togglebutton.p-button.p-highlight {
  background: none;
  border-color: #000;
  color: #ed0000 !important;
}

.title-h {
  font-size: 18px;
}
.filter-title {
  font-weight: 800;
  text-align: initial;
  font-size: 28px;
}
.restaurant-title {
  border-bottom: 10px solid #6d5fa5;
}
.restaurant-title h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  text-transform: uppercase;
  color: #6d5fa5;
  margin-bottom: 2px;
  text-align: initial;
  margin-top: 2px;
}
.news-title {
  border-bottom: 10px solid #ff515b;
}
.news-title h1 {
  font-weight: 800;
  line-height: 55px;
  text-transform: uppercase;
  color: #ff515b;
  margin-bottom: -11px;
  text-align: initial;
}
.btn-news.p-button,
.btn-news.p-button:enabled:hover {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  line-height: 45px;
  text-transform: uppercase;
  background: #ff515b;
  border-radius: 0;
  border-color: #ff515b;
}
.btn-news.p-button span {
  color: white;
}

.footer {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 250px;
}
.footer footer {
  height: 40px;
  display: flex;
}

.footer-logo {
  width: 120px;
  align-self: center;
}

.footer-contact {
  min-height: 350px;
  padding-left: 1rem !important;
}

.footer-contact a {
  color: #000000;
  text-decoration: none;
  font-weight: 800;
  font-size: 26px;
}

.footer-contact .footer-mobile-contact a span,
.footer-contact .footer-mobile-contact span {
  font-size: 14px;
}

.form-Write-to--title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  margin: 0.5rem;
  line-height: 44px;
  text-align: start;
}

.footer-navLink {
  width: 100px;
}

.footer-design {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 29px;
  color: #000000;
}
.writeToUs-btn {
  width: 50% !important;
}

.c-hr--black {
  height: 1px;
  background-color: black;
}

.backup-link {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  color: black;
}

.filters-item {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  color: #000000;
}

.cursor-pointer:hover {
  cursor: pointer !important;
}

.main-filters {
  margin: 20px 0px 100px;
}

.main {
}

.checkbox_error > div[role="checkbox"] {
  border: 2px solid #eb5e55;
}

.c-card-restaurant {
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 3px;
}

.c-bg-orange {
  background: #ff9a11 !important;
  border: 1px solid #ff9a11 !important;
}
.c-orange-outlined {
  background-color: transparent !important;
  color: #ff9a11 !important;
  border: 1px solid !important;
}
.c-bg-orange:focus,
.c-orange-outlined:focus {
  box-shadow: 0 0 0 0.2rem #fab759 !important;
}

.p-divider-dashed.p-divider-horizontal:before {
  border-top-style: solid !important;
}

.p-sidebar-mask.p-component-overlay {
  z-index: 100 !important;
}

.stocks-form-phone {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 12px;
}

.sms-text-result,
.sms-text-result-bold {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
}
.sms-text-result-bold {
  font-family: MontserratBold !important;
}

.p-divider.p-divider-horizontal {
  margin: 0.5rem 0;
  padding: 0 1rem;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  display: block;
  width: 100%;
  padding-left: 32px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none !important;
}

.p-dialog .p-dialog-header {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom: 2px solid #d8d8d8ab !important;
}

.p-dialog .p-dialog-footer {
  text-align: center !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding: 0 0.5rem 0.5rem 0.5rem !important;
}

.p-dialog-resizable .p-dialog-header {
  cursor: default !important;
}

.p-dialog .p-dialog-header {
  padding: 1rem !important;
}

.win-dialog--all {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 557px;
}

.win-dialog--all div[class="p-dialog-header"] {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.win-dialog--all.win-no-footer div[class="p-dialog-content"] {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.win-dialog--all div[class="p-dialog-content"] {
  padding: 1.5rem;
  height: 100vh;
}

.win-dialog--all .win-span{
  font-family: 'MontserratRegular';
  font-size: 14px;
}

.win-dialog--all div[class="p-dialog-footer"] {
  background: linear-gradient(
      0deg,
      rgba(58, 51, 53, 0.04),
      rgba(58, 51, 53, 0.04)
    ),
    #ffffff;
  padding: 1rem;
  font-family: "MontserratRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #a7a7a7;
}

.win-dialog--all div[class="p-dialog-footer"] {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.win-dialog--all div[class="p-dialog-footer"] span {
  cursor: default;
}
.win-dialog--all div[class="p-dialog-footer"] span b:hover {
  cursor: pointer;
  text-decoration: underline;
}

.win-dialog {
  /* background-image: url(./img/ellipse_1.png), url(./img/ellipse_2.png),
    url(./img/ellipse_3.png); */
  background-color: white;
  background-repeat: no-repeat;
  background-position: top right, top left, bottom right;
  min-width: 250px;
  min-height: 200px;
}
.win-dialog > div {
  background: none !important;
}
.win-dialog > div:first-child {
  padding: 0.5rem 2rem 0.25rem 2.5rem;
}
.win-dialog > div:last-child {
  padding: 0px 4rem 2rem 2.5rem;
}

.whiteBtn {
  width: 100%;
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #1f1f1f;
  border: 3px solid #1f1f1f;
  box-sizing: border-box;
  padding: 0.5rem;
  background-color: white;
  cursor: pointer;
}

.wrapper-spinner{
  display: flex;
  justify-content: center;
  min-height: 180px;
  align-items: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.c-card-restaurant {
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 3px;
}

.c-bg-orange {
  background: #ff9a11 !important;
  border: 1px solid #ff9a11 !important;
}
.c-orange-outlined {
  background-color: transparent !important;
  color: #ff9a11 !important;
  border: 1px solid !important;
}
.c-bg-orange:focus,
.c-orange-outlined:focus {
  box-shadow: 0 0 0 0.2rem #fab759 !important;
}

.p-divider-dashed.p-divider-horizontal:before {
  border-top-style: solid !important;
}

.p-sidebar-mask.p-component-overlay {
  z-index: 100 !important;
}

.p-inputtext {
  background: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #495057 !important;
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
}
.p-inputwrapper-focus > .p-inputtext:enabled:focus,
.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: #495057 !important;
}
.p-inputtext:enabled:hover {
  border-color: #495057 !important;
}
.c-label {
  font-family: MontserratBlack;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: initial;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border-color: #ffcc75 !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-highlight {
  background: #ffa307 !important;
  border-color: #ffa307 !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #ffa307 !important;
  background: #ffa307 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #ffa307 !important;
  background: #ffa307 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #ffa307 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem #ffcc75 !important;
  border-color: #ffa307 !important;
}

.p-button:focus {
  box-shadow: 0 0 0 0.2rem #ffcc75 !important;
}

.p-datepicker.p-component.p-connected-overlay-enter-done{
  /* left: 40px !important; */
}

.border-warning{
  border: 1px solid #ff515b;
  border-radius: 4px;
}

.form-button{
  width: 100%;
  background: #ffa307;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: white;
  font-family: "MontserratBold";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.1px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link i{
  margin-right: .5rem;
}

.p-dataview .p-dataview-content{
  /* min-height: calc(100vh - 410px); */
}

.p-datatable-row-expansion .p-dataview .p-dataview-content{
  min-height: 50px !important;
}

.p-dataview-emptymessage {
  text-align: center;
}

.window-border-bottom.p-dialog .p-dialog-content{
  border-radius: 0px 0px 0px 0px !important;
}

.p-dialog{
  max-height: 100% !important;
}

.p-tabview .p-tabview-nav-btn.p-link {
  width: 2rem !important;
}

.p-datatable-emptymessage > td{
  text-align: center !important;
  display: table-row !important;
}

.toolbar-custom{
  background: none !important;
  padding: 0.25rem 1rem !important;
  border: none !important;
}

.dialog-footer--btn {
  width: 100% !important;
}

.color-link, .color-link span{
  color: #196dff !important;
}

.block-label-title {
  font-family: 'MontserratBold';
  font-weight: 800;
}

.block-label-value {
  font-family: 'MontserratRegular';
}

.card-clear{
  background-color: #fff;
  border-radius: 5px;
}

.p-button.google {
  background: linear-gradient(to left, #cd4b27 50%, #aa3000 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: #aa3000;
  border-radius: 10px;
}
.p-button.google:hover {
  background-position: left bottom !important;
  background: #aa3000 !important;
  border-color: #aa3000 !important;
}
.p-button.google i {
  background-color: #aa3000;
  padding: 1.25rem;
}
.p-button.google:focus {
  box-shadow: 0 0 0 1px #cd4b27;
}

.p-button.vk {
  background: linear-gradient(to left, rgb(0, 119, 255) 50%, rgb(0 88 255) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: rgb(0 88 255);
  border-radius: 10px;
}
.p-button.vk:hover {
  background-position: left bottom !important;
  background: rgb(0 88 255) !important;
  border-color: rgb(0 88 255) !important;
}

.p-button.vk i {
  background-color: rgb(0 88 255);
  padding: 12px 18px;
  display: block;
  width: 56px;
  height: 56px;
  font-size: 24px;
}

.p-button.vk:focus {
  box-shadow: 0 0 0 1px rgb(0, 119, 255);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
