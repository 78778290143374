@keyframes visible {
  0% { transform: scale(0.1); display: inline-block; }
  50% { transform: scale(0.5); }
  100% { transform: scale(1); }
}

@keyframes hidden {
  0% { transform: scale(1); }
  50% { transform: scale(0.5); }
  100% { transform: scale(0.1); display: none; }
}

.nav_menu{
  border: 1px solid black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 5px;
}

.nav_icon_visible{
  transform: scale(0);
  animation: visible .3s  forwards;
}

.nav_icon_hidden{
  transform: scale(1);
  display: none;
  animation: hidden .3s forwards;
}


.menu_sidebar > div > button{
  /* color: white !important; */
  position: absolute;
  top: 14px;
} 
