.dataview-task{
  width: 100%;
}

.dataview-task .p-dropdown {
  width: 14rem;
  font-weight: normal;
  font-family: 'MontserratRegular';
}

.dataview-task .task-name {
  font-size: 1.5rem;
  font-weight: 700;
  cursor: default;
}

.dataview-task .task-address {
  font-size: 12px;
  line-height: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  letter-spacing: .15rem;
  cursor: default;
}

.dataview-task .task-description {
  margin: 0 0 1rem 0;
  cursor: default;
  font-style: italic;
  padding-right: 0.5rem;
}

.task-category{
  cursor: default;
}

.dataview-task .task-date-icon {
  vertical-align: middle;
  margin-right: .5rem;
  padding-bottom: 4px;
}

.dataview-task .task-date {
    font-weight: 600;
    vertical-align: middle;
}

.dataview-task .task-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.dataview-task .task-list-item img {
  width: 150px;
    /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  margin-right: 2rem;
  border-radius: var(--border-radius-custom);
}

.dataview-task .task-list-item .task-list-detail {
    flex: 1 1 0;
}

.dataview-task .task-list-item .p-rating {
    margin: 0 0 .5rem 0;
}

.dataview-task .task-list-item .task-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .5rem;
    align-self: flex-end;
}

.dataview-task .task-list-item .task-list-action {
    display: flex;
    flex-direction: column;
}

.dataview-task .task-list-item .p-button {
    margin-bottom: .5rem;
    margin-top: .75rem;
}

.dataview-task .task-grid-item {
    margin: .5em;
    border: 1px solid var(--surface-border);
    padding: 2rem;
}

.task-badge {
  text-align: center;
  font-family: 'MontserratBlack';
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
  cursor: default;
}

.status-create{
  color: #256029;
  border-bottom: 1px solid #256029;
}

.status-raffle{
  color: #ff9a11;
  border-bottom: 1px solid #ff9a11;
}

.status-active{
  color: #ff9a11;
  border-bottom: 1px solid #ff9a11;
}

.status-complete{
  color: #767676ab;
  border-bottom: 1px solid #767676ab;
}

.task-button-complete {
  background-color: #ffffff !important;
  color: #767676 !important;
  border-color: #767676 !important;
}

.task-button-winner{
  background-color: #ff9a11 !important;
  border-color: #ff9a11 !important;
  box-shadow: 1px 1px 4px 0px #a07100;
}

.status-verification, .status-verified {
  color: #256029;
  border-bottom: 1px solid #256029;
}

.task-list-action .p-button{
  font-family: 'MontserratBold';
}

.dataview-task .task-grid-item .task-grid-item-top,
    .dataview-task .task-grid-item .task-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dataview-task .task-grid-item img {
    width: 75%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
}

.dataview-task .task-grid-item .task-grid-item-content {
    text-align: center;
}

.dataview-task .task-grid-item .task-price {
    font-size: 1.5rem;
    font-weight: 600;
}

@media screen and (max-width: 576px) {
    .dataview-task .task-list-item {
        flex-direction: column;
        align-items: center;
    }

    .dataview-task .task-list-item img {
        width: 75%;
        margin: 2rem 0;
    }

    .dataview-task .task-list-item .task-list-detail {
        text-align: center;
    }

    .dataview-task .task-list-item .task-price {
        align-self: center;
    }

    .dataview-task .task-list-item .task-list-action {
        display: flex;
        flex-direction: column;
    }

    .dataview-task .task-list-item .task-list-action {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
    }
}