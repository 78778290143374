.content{
  color: black;
  font-family: MontserratRegular;
  font-size: 12px;
  padding: .25rem;
  cursor: default;
}

.content h4{
  margin: .25rem 0;
}

.content p{
  margin: 0;
}

.toast > div > div {
  background-color: #ffffff !important;
}

.toast {
  width: 21rem !important;
  right: 10px !important;
  top: 10px !important;
}