.city {
    text-align: end;
  }
  
  .city > span,
  title {
    font-family: MontserratRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    cursor: default;
  }
  .city u {
    font-family: MontserratBlack;
  }
  
  .dialog > div:first-child > span {
    font-family: MontserratBold !important;
    font-style: 800;
    /* font-weight: bold; */
    font-size: 22px !important;
    cursor: default;
  }
  
  .dialog div.p-dialog-header {
    padding: 1.5rem 1.5rem 0.75rem !important;
  }
  
  .title {
    /* margin: 3px 0; */
    text-align: initial;
  }
  .city u {
    margin-top: 5px;
  }
  
  .city u,
  .list span {
    cursor: pointer;
  }
  /* .city u:hover,
  .list span:hover {
    text-shadow: 2px 1px 16px #6d5fa5;
  } */
  .list {
    padding: 0.75rem 0;
  }
  .list span {
    font-family: MontserratRegular !important;
    font-style: normal;
    /* font-weight: bold; */
    display: block;
    width: 100%;
    font-size: 16px !important;
    line-height: 30px;
    padding-right: 0.5rem;
    text-align: initial;
  }
  
  .autocomplite {
  }
  .autocomplite input {
    width: 100%;
  }
  
  .popup {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
  
  .popup:before {
    content: "";
    position: absolute;
    bottom: 100%;
    /* left: 21px; */
    border: 10px solid transparent !important;
    border-bottom-color: rgba(51, 51, 51, 0.8) !important;
    background: transparent;
    width: 0;
    height: 0;
  }
  .popup:after {
    border-bottom-color: rgba(51, 51, 51, 0.8) !important;
    border-radius: 5px !important;
  }
  
  .popupContent {
    display: flex;
    color: #fff !important;
    font-family: MontserratBold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    margin-left: -1rem;
    max-width: 360px;
  }
  
  .geoBtn {
    display: flex;
    background: #ff9a11;
    border: 3px solid#fff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 16px;
    cursor: pointer;
    position: relative;
  }
  .geoBtn svg,
  .geoBtn span {
    color: #fff;
  }
  .geoBtn span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 70px);
    font-family: "MontserratRegular";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
  .geoError {
    position: absolute;
    z-index: 10;
    background-color: #c3c2c280;
    backdrop-filter: blur(2px);
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    top: -10px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 0.5rem;
    font-family: "MontserratBold";
  }
  
  @media screen and (min-width: 568px) {
    /* .dialog > div:first-child > span {
      font-size: 30px !important;
    } */
    .list span {
      width: 48%;
    }
    .city > span {
      font-size: 12px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .city {
      text-align: start;
    }
    .city > span {
      font-size: 14px;
    }
  }
  
  @media screen and (min-width: 992px) {
    .city > span {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 512px) {
    .popup {
      left: 50px;
      margin-right: 0.25rem;
      margin-left: 0.5rem;
    }
  }