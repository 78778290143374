.stock {
  color: #ed0000;
  margin: 12px 0;
  flex-direction: column;
  align-content: flex-end;
}

.stock-title {
  border-bottom: 2px solid;
  width: 180px;
  transition: width 0.3s ease-in-out 0.6s;
}
.stock-title span {
  font-family: MontserratBlack;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
}
.stock-title span:hover {
  cursor: pointer;
}
.stock-title--active {
  width: 100%;
  transition: width 0.3s ease-out;
}

/* .stock-description{

} */
.stock-description {
  opacity: 0;
  height: 0px;
  width: 0px;
  visibility: hidden;
  transition: height 0.3s ease-in-out 0.3s, visibility 0.3s ease-in 0.3s,
    opacity 0.3s ease-in, width 0s ease-in-out 0.6s;
  /* transition: height 0.3s ease-in-out 0.3s, visibility 0.3s ease-in 0.3s; */
}
.stock-description--active {
  opacity: 1;
  height: calc(100% - 40px);
  visibility: visible;
  width: 100%;
  transition: height 0.3s ease-in-out 0.3s, visibility 0.1s ease-in,
    opacity 0.3s ease-in 0.3s, width 0s ease-in-out 0.3s;
}

.stock-get-promo--btn {
  background-color: black;
}
.stock-get-promo--btn:hover {
  cursor: pointer;
}
.stock-get-promo--btn span {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.stock-social {
  color: #000;
}
.stock-social--item {
  cursor: pointer;
  display: flex;
  width: 100%;
  margin: 0.25rem 0;
  color: black;
}
.stock-social--item span {
  margin-left: 0.5rem;

  font-family: MontserratBlack;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
}
.stock-article {
  padding: 10px 15px;
  text-align: initial;
}
.stock-article--active {
}
.stock-title-description {
  font-family: MontserratBlack;
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 15px;
  color: #000;
}
.stock-requirement-description {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 15px;
  color: #000;
}
.stock-present {
  border: 1px solid black;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  margin: 10px auto;
  font-family: MontserratBlack;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 32px;
  padding: 0.25rem;
}
.stock-footer {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: black;
  text-align: initial;
}
.stock-footer span {
  display: block;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 768px) {
  .stock-article {
    padding: 10px 0px;
  }
}
