.light-page {
  width: 100%;
  height: 100vh;
  background: none;
  position: absolute;
  z-index: 9999;
}

.light-block-border-left, .light-block-border-right {
  position: absolute;
  border: 2px solid #fff;
  height: 100%;
  border-radius: 5px;
}

.light-block-border-left {
  right: -3px;
}

.light-block-border-right {
  left: -3px;
}

.light-block-bottom, .light-block-top,
.light-block-left, .light-block-right {
  position: absolute;
  background-color: #000000b8;
  z-index: 9999;
  opacity: 1;
  transition: all .5s ease 0s;
}

.lb-start-opacity {
  opacity: 0;
  z-index: 9999;
}

.lb-start{
  opacity: 1;
  z-index: 9999;
  transition: all .5s ease 0s;
}

.light-block-left{
  left: 0;
  top: 16px;
  width: calc(100% - 50px);
  height: calc(80px - 38px);
}

.lb-left-step1{
  left: 0;
  top: 200px;
  width: 3px;
  height: 200px;
  transition: all .5s ease 0s;
}

.light-block-top{
  width: 100%;
  height: 16px;
}

.lb-top-step1{
  left: 0;
  top: 0;
  width: 100%;
  height: calc(80px + 120px);
  transition: all .5s ease 0s;
}

.light-block-right{
  right: 0;
  top: 16px;
  width: 6px;
  height: calc(80px - 37px);
}

.lb-right-step1{
  right: 0;
  top: 200px;
  width: calc(100% - 319px);
  height: calc(80px - -120px);
  transition: all .5s ease 0s;
}

.light-block-bottom{
  width: 100%;
  height: calc(100% - 58px);
  bottom: 0;
}

.lb-bottom-step1{
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 400px);
  transition: all .5s ease 0s;
}

.light-block-description {
  z-index: 9999;
  position: absolute;
  text-align: center;
  background-color: #00000087;
  color: #fff;
  font-weight: bold;
  width: 186px;
  padding: 0.75rem;
  border: 2px solid;
  border-radius: 5px;
  left: calc(25% - 40px);
  top: 34%;
}

.lb-description-step1 {
  left: calc(25% + 60px);
  top: 4%;
  transition: all .5s ease 0s;
}

.light-block-arrow {
  position: fixed;
  bottom: calc(100% - 34% - 30px);
  left: calc(25% - -167px);
  width: calc(100% - 25% - 184px);
  height: calc(34% - 36px);
  z-index: 9999;
  transform: rotate(180deg);
}

.lb-arrow-step1 {
  bottom: calc(100% - 198px);
  left: 48px;
  width: calc(100% - 75% - 2px);
  height: 150px;
  transform: rotate(0deg);
  transition: all .5s ease 0s;
}

.light-block-arrow__body {
  width: 100%;
  height: 90%;
  margin-left: 9px;
  border-width: 5px 0 0 5px;
  border-style: dashed;
  border-color: #fff;
  border-top-left-radius: 100%;
}

.lb-arrow__body-step1 {
  transition: all 1s ease 0s;
}

.light-block-arrow__body::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  border-width: 14px 12px 0;
  border-style: solid;
  border-color: #fff transparent transparent;
}

@media screen and (min-width: 992px) {
  .youtube-size-start-media {
    height: 256px !important;
    width: 512px !important;
  }
}