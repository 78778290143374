body {
  margin: 0;
  font-family: "MontserratRegular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

img {
  aspect-ratio: attr(width) / attr(height);
}

:root {
  --font-family-bold: 'MontserratBold';
  --font-family-regular: 'MontserratRegular';
  --color-silver: #909090;
  --color-dark-shadow: #4C4C4C;
  --color-header-down-level:#909090;
  --background-def: #FDFCFC;
  --color-ground: #1e3050;
  --border-radius-custom: 10px;
  --box-shadow: 0 4px 4px #00000052;
  --color-green: #4CAF50;
  --color-next: #3B8F12;
  --color-red: #FF0202;
  --color-second: #FE5661;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-bold);
  cursor: default;
}

p{
  cursor: default;
}

.docs ol {
  /* убираем стандартную нумерацию */
  list-style: none;
  /* Идентифицируем счетчик и даем ему имя li. Значение счетчика не указано - по умолчанию оно равно 0 */
  counter-reset: li;
}
.docs li:before {
  /* Определяем элемент, который будет нумероваться — li. Псевдоэлемент before указывает, что содержимое, вставляемое при помощи свойства content, будет располагаться перед пунктами списка. Здесь же устанавливается значение приращения счетчика (по умолчанию равно 1). */
  counter-increment: li;
  /* С помощью свойства content выводится номер пункта списка. counters() означает, что генерируемый текст представляет собой значения всех счетчиков с таким именем. Точка в кавычках добавляет разделяющую точку между цифрами, а точка с пробелом добавляется перед содержимым каждого пункта списка */
  content: counters(li, ".") ". ";
}

