.socLink {
    margin-left: -0.5rem;
  }
  .socLink a {
    position: relative;
    width: 24px;
    height: 24px;
    display: block;
    font-size: 24px;
    color: #4d4d4d;
    margin: 0.5rem;
  }
.blocked {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #bfbfbfb3;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  color: #ac1f1f;
  font-weight: 900;
  text-align: center;
}