.await-geolocation{
    position: fixed;
    z-index: 9999;
    background-color: #f9f9f97d;
    backdrop-filter: blur(7px);
    height: 100%;
    width: 100%;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
}

.globe-container,
.globe {
    width: 200px;
    height: 200px;  
}
.globe-container {
    position: relative;  
    display: inline-block;
    margin: 30px;
    transform: scale(.5);
}
.globe {
  position: relative;  
  display: block;
  margin: 0;
  padding: 0;
  top: 0; 
  left: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
}
.globe-worldmap,
.globe-worldmap-front,
.globe-worldmap-back,
.globe-sphere,
.globe-outer-shadow,
.globe-inner-shadow {
    position: absolute; 
    display: block; 
    margin: 0; 
}
.globe-sphere,
.globe-outer-shadow,
.globe-inner-shadow {
    left: 0; 
    top: 0; 
    width: 200px; 
    height: 200px;
    background-position: 0 0; 
    background-repeat: no-repeat;
}
.globe-worldmap {
  left: 0; 
  top: 0; 
  width: 200px; 
  height: 200px; 
  overflow: hidden !important;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50% !important;
  -khtml-border-radius: 50%;
}
.globe-worldmap-front,
.globe-worldmap-back {
    left: 0; 
    top: 0; 
    width: 1000px; 
    height: 200px; 
    overflow: visible;
    background-image: url(worldmap.svg), 
        linear-gradient(90deg, rgb(255 188 87 / 30%) 0%, rgb(75 213 118 / 30%) 35%, rgb(255 188 87 / 30%) 50%, rgb(75 213 118 / 30%) 85%, rgb(255 188 87 / 30%) 100%);
    background-blend-mode: color-dodge;
}
.globe-outer-shadow { 
    left: 0; 
    top: 186px; 
    width: 200px; 
    height: 30px;
    background-image: url(outer_shadow.svg);
}
.globe-inner-shadow { 
    background-image: url(inner_shadow.svg);
}
 
.globe-worldmap-front { 
    background-position: 0px 0px;
    animation: textureSpinreverse 8s linear infinite;
}
.globe-worldmap-back { 
    background-position: 0px -220px;
    animation: textureSpin 8s linear infinite;
}

.loading {
  transform: scale(1.5);
  width: 222px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: -60px;
}

.loading:after {
    content: '.';
    animation: loading 1s ease alternate infinite;
}

@keyframes loading {
    60%  { text-shadow: 0.35em 0 0 currentColor; }
    100% { text-shadow: 0.35em 0 0 currentColor, 0.75em 0 0 currentColor; }
}

@keyframes textureSpin {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-47.5%);
    }
}
@keyframes textureSpinreverse {
    from {
        transform: translateX(-47.5%);
    }
    to {
        transform: translateX(0);
    }
}