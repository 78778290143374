.logotype{
  width: 94px;
  height: 46px;
}

.ancor{
  text-decoration: underline;
}

.main_container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
}

.title{
  font-size: 18px;
  font-family: var(--font-family-bold);
  margin: 16px 0;
}

.filter{
  width: 90%;
  margin: 24px auto 24px auto;
  max-width: 969px;
}

.float_icon{
  left: .85rem !important;
}

.search_input{
  border-radius: 6px 0px 0px 6px !important;
  border: 1px solid var(--color-ground) !important;
  border-right: 0px !important;
  width: 100%;
  padding-left: 40px;
}

.search_btn{
  text-align: center !important;
  padding: 12px 16px !important;
  overflow: inherit !important;
  border-radius: 0px 6px 6px 0px !important;
  background: var(--color-ground) !important;
  border-color: var(--color-ground) !important;
}

.filter .search_input:focus-visible {
  box-shadow: 0px 0px 0px 0px !important;
}

.filter .search_btn:focus {
  box-shadow: 0px 0px 0px 0px !important;
}

.input_group, .float_label {
  display: flex !important;
  align-items: stretch;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}


.filter-description{
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.filter-description h1{
  margin: 8px 0;
  font-weight: 100;
  font-size: 22px;
}

.filter-description strong{
  font-family: var(--font-family-bold);
  letter-spacing: 2px;
}

.filter-description span{
  text-align: center;
  font-family: var(--font-family-regular);
  font-size: 12px;
}

.filter_icon{
  padding: 6px;
  width: 60px;
}

.filter_icon_name{
  font-size: 11px; 
  margin: 8px 0 0 0; 
  text-align: center;
  font-family: var(--font-family-regular)
}

.banner{
  width: 90%;
  max-width: 969px;
  height: 216px;
  /* border-radius: var(--border-radius-custom); */
  
  margin: 24px auto;
  position: relative;
  /* border: 1px solid #0000001f; */
  background-color: #f5e8d5;
}

.banner_image{
  background: url('../../../../../public/images/banner_1_mobile.png');
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  box-shadow: var(--box-shadow);
  /* filter: brightness(1) blur(1px); */
}

.banner_info{
  position: absolute;
  bottom: 0;
  z-index: 2;
  padding: 8px 12px;
  width: 100%;
  background-color: #00000060;
  display: none;
}

.banner_info h2{
  color: white;
  font-family: var(--font-family-bold);
  font-size: 14px;
  display: inline-block;
  width: 258px;
  line-height: 24px;
  margin: 4px 0;
}

.banner_info a{
  display: flex;
  background-color: white;
  width: 148px;
  height: 30px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 12px;
  font-family: var(--font-family-bold);
}

.events{
  width: 90%;
  margin: 24px auto;
}

.events h4{
  font-size: 18px;
  font-family: var(--font-family-bold);
  color: var(--color-second);
}

.card sup{
  font-size: 8px;
  font-family: var(--font-family-regular);
  color: var(--color-next);
  vertical-align: top;
}

.recent_views {
  width: 90%;
  margin: 24px auto;
}

.block.card{
  padding: 16px;
}

.card_header{
  font-family: var(--font-family-bold);
  font-size: 14px;
  margin: 0;
}

.card_header_city{
  font-size: 10px;
  padding: 0 4px;
  font-family: var(--font-family-regular);
}

.empty_list{
  font-family: var(--font-family-regular);
  font-size: 12px;
  text-align: center;
  display: block;
}

.card_image{
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.card_type, .card_title, .card_description, .card_next{
  font-size:12px;
}

.card_type{
  margin: 4px 0;
  text-transform: uppercase;
  text-decoration: underline;
  font-family: var(--font-family-bold);
  color: var(--color-second);
}

.card_title{
  font-family: var(--font-family-bold);
  margin: 0;
}

.card_description{
  font-family: var(--font-family-regular);
  margin-top: 8px;
  display: block;
}

.card_next {
  margin-top:4px;
  color: var(--color-next);
  cursor: pointer;
}

.about{
  width: 90%;
  margin: 24px auto;
}

.about .text, .text p {
  font-size: 12px;
  font-family: var(--font-family-regular);
  line-height: 1.2rem;
}

.about .image{
  width: 118px;
  padding: 12px;
}

/* Copy styles from review shopper */
.review_manager{
  font-family: var(--font-family-regular);
  font-size: 14px;
  font-weight: 100;
  margin: 0px;
}

.review_avatar{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.review_photo{
  border-radius: var(--border-radius-custom);
  width: inherit;
  box-shadow: var(--box-shadow);
  height: inherit;
  object-fit: cover;
  cursor: pointer;
}

.review_manager_text{
  font-family: var(--font-family-regular);
  font-size: 12px;
  font-weight: 100;
  color: #000000a6;
}

.review_text{
  font-family: var(--font-family-regular);
  font-size: 12px;
}

.swiper{

}

.swiper div[class*="swiper-button-prev"]{
  left: -8px;
}

.swiper div[class*="swiper-button-next"]{
  right: -8px;
}

.swiper div[class*="swiper-button-prev"]:after, .swiper div[class*="swiper-button-next"]:after {
  color: #bbb9b9 !important;
  font-size: 18px;
}

.rating{
  padding-left: 6px;
  letter-spacing: 2px;
}

.rating > span[class*="p-rating-icon"]{
  font-size: 14px;
}

.rating strong{
  margin-left: 4px;
}
/* Copy styles from review shopper */

@media screen and (min-width: 768px) {
  .filter{
    margin: 48px auto;
  }

  .filter_icon{
    width: 74px;
  }

  .filter_icon_name{
    font-size: 14px; 
  }

  .filter-description h1{
    margin: 16px 0;
    font-size: 36px;
  }

  .filter-description span{
    font-size: 16px;
  }

  .banner{
    height: 300px;
  }

  .banner_image {
    background: url('../../../../../public/images/banner_1_desktop.png');
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .banner_info h2{
    color: white;
    font-size: 24px;
    width: 100%;
  }

  .banner_info span{
    color: white;
    font-family: var(--font-family-regular);
    font-size: 16px;
    margin-bottom: 12px;
  }
  
  .banner_info a{
    width: 178px;
    height: 44px;
    border-radius: 16px;
    font-size: 16px;
  }
  .title{
    font-size: 24px;
  }

  .about .text, .text p {
    font-size: 14px;
  }

  .about .image{
    width: 206px;
    padding: 12px;
  }

  .review_text{
    font-size: 14px;
  }
}

@media screen and (min-width: 1200px) {
  .filter{
    margin: 56px auto 36px auto;
  }

  .filter-description h1{
    word-spacing: 30px;
    font-size: 48px;
  }

  .filter-description span{
    font-size: 20px;
  }

  .filter-description h1{
    margin: 20px 0;
  }

  .filter_icon_name{
    font-size: 16px; 
  }

  .banner{
    height: 406px;
  }

  .header_link {
    font-size: 16px;
  }

  .events h4{
    color: var(--color-second)
  }

  .title{
    font-size: 28px;
  }

  .about .text, .text p {
    font-size: 16px;
  }

  .about .image{
    width: 284px;
  }

}