.main {
  position: fixed;
  left: -1px;
  top: 220px;
  z-index: 3;
}

.main svg {
  background-color: #ffffff;
  border-radius: 0px 5px 5px 0px;
  width: 46px !important;
  height: 46px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  color: #04bfc3;
  padding: 8px;
}