.favorite-button {
  background: #ffa307 !important;
  border: 1px solid #ffa307 !important;
}

.review_photo{
  border-radius: var(--border-radius-custom);
  width: inherit;
  box-shadow: var(--box-shadow);
  height: inherit;
  object-fit: cover;
  cursor: pointer;
}

.main_container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
}

.block{
  padding: 16px 20px;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-custom);
}

.p-button.button-content {
  background: #1F1F1F !important;
  border: 1px solid #1F1F1F !important;
}

.p-button {
  border-radius: 10px !important;
}

.p-button.button-content:enabled:active, .p-button.button-content:not(button):not(a):not(.p-disabled):active {
  background: #535151 !important;
  border-color: #535151 !important;
}

.p-button.button-content.p-button-text:enabled:active, .p-button.button-content.p-button-text:enabled:hover {
  background: #ffffff !important;
  border-color: #535151 !important;
}

.p-button.button-content:enabled:hover, .p-button.button-content:not(button):not(a):not(.p-disabled):hover {
  background: #535151 !important;
  border-color: #535151 !important;
}

.p-component.p-button.button-content:focus {
  box-shadow: 0 0 0 0.2rem #b3b3b2 !important;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
  min-width: 1.357rem !important;
  height: 1.357rem !important;
}

.p-button.p-button-text {
  background-color: transparent !important;
  color: #000 !important;
  border-color: #000 !important;
}

.rating-size-mobile span{
  font-size: 10px !important;
}

.p-overlaypanel.overlay-panel--search{
  margin-top: 0px;
}

.p-overlaypanel.overlay-panel--search:before, .p-overlaypanel.overlay-panel--search:after {
  content: none;
}

.p-overlaypanel.overlay-panel--search .p-overlaypanel-close {
  display: none;
}

.p-skeleton {
  background-color: #d9d9d9 !important;
}

.pi.icon-h-15{
  font-size: 1.5rem !important;
}

.min-width-50{
  min-width: 50%;
}

.mobile-header{
  /* position: fixed;
  top: 56px; */
  background-color: white;
  /* z-index: 5; */
}

.mobile-header .input-main-style{
  border-radius: 5px;
  box-shadow: 1px 1px 8px -4px #3d3d3dd4;
  padding: 0.25rem;
}

.mobile-header .input-main-style .p-inputtext {
  border: none !important;
}

.mobile-header img{
  width: 100px;
  height: 48px;
}

.mobile-header .button-input{
  background: none !important;
  border: none !important;
  color: #04bfc3;
  border-radius: 5px;
  box-shadow: 1px 1px 8px -4px #3d3d3dd4;
  height: 2.5rem;
  width: 2.5rem;
  padding: 6px;
}

.mobile-header .button-input:enabled:hover, .mobile-header .button-input:not(button):not(a):not(.p-disabled):hover {
    background: none;
    color: #04bfc3;
    border: none;
}

.mobile-header .button-input:focus {
  box-shadow: none !important;
}

.input-main-style i {
  /* z-index: 1; */
}

.menu-sidebar-simple{
  border-width: 0px !important;
  width: 100% !important;
}

.menu-sidebar-mix .p-panelmenu-header > a.p-panelmenu-header-link{
  background-color: #fff !important;
  border: none;
}

.menu-sidebar-mix .p-panelmenu-content {
  border: none !important;
}

.menu-sidebar.p-sidebar .p-sidebar-header{
  padding: 1rem 1rem 0rem 0.25rem;
  /* background-color: #c1c1c1a2; */
}

.menu-sidebar.header-hidden > .p-sidebar-header{
  display: none !important;
}

.menu-sidebar-header {
  /* background-color: #c1c1c1a2; */
  box-shadow: inset 0px -2px 0px 0px #c7c7c7cc;
  padding: 0.5rem 1rem;
}

.filter-sidebar-header {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-family: 'MontserratRegular';
}

.menu-sidebar .p-divider.p-divider-horizontal{
  margin: .25rem 0;
}

.question-style{
  display: flex;
  border: 1px solid #ede8e9;
  border-radius: 8px;
  padding: 12px 18px;
  margin-bottom: .25rem !important;
  width: 100%;
  justify-content: space-between;
}

.p-rating.review-rating .p-rating-icon {
  font-size: 0.7rem;
}

.task-status{
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.task-status-new{
  background-color: #b3e5fc;
  color: #23547b;
}

.task-status-active{
  background-color: #feedaf;
  color: #8a5340;
}

.task-status-start{
  background-color: #eccfff;
  color: #694382;
}

.task-status-verification{
  background-color: #ffffff;
  color: #dd0909;
}

.task-status-payout{
  background-color: #ffe9cd;
  color: #c68337;
}

.task-status-payment{
  background-color: #dd0909;
  color: #ffffff;
}

.task-status-confirm{
  background-color: #c8e6c9;
  color: #256029;
}

.task-status-reject{
  background-color: #ffcdd2;
  color: #c63737;
}

@media screen and (min-width: 768px) {
  .question-style{
    width: auto;
  }

  .block{
    padding: 16px 26px;
  }
}