.ymaps-2-1-79-ymaps-geolink-dark {
  color: #fff0 !important;
  z-index: 2;
}
.ymaps-2-1-79-geolink-processed:before {
  content: "";
  display: inline-block;
  background: none !important;

  width: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -24px;
  height: auto;
}

.ymaps-2-1-79-geolink-processed:after {
  border-bottom: 0px solid !important;
}
.ymaps-2-1-79-geolink-popup {
  left: -100px !important;
}

.geolink-right .ymaps-2-1-79-geolink-popup {
  left: -30px !important;
}

.text-opacity {
  font-size: 0px !important;
}
.geolink {
  cursor: pointer;
  text-decoration: none !important;
}
.geolink:hover {
  /* text-decoration: underline; */
}
