.navLink {
}
.anchor {
  cursor: pointer;
  /* margin:0px .5rem !important; */
}
.navLink a {
  text-decoration: none;
  /* margin-bottom: 0.75rem; */
}
.socLink a {
  color: #000;
  font-size: 16px;
  /* margin: 0px 7px; */
  margin: 0.5rem;
  display: block;
}
.socLink a svg{
  font-size: 24px !important;
}

.navLinkItem,
.anchor {
  color: #000;
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  display: block;
}

@media screen and (min-width: 576px) {
}

@media screen and (min-width: 768px) {
  .socLink a {
    font-size: 20px;
    margin: 0.5rem;
  }
}

@media screen and (min-width: 992px) {
  .navLinkItem,
  .anchor {
    font-size: 14px;
  }
}