.p-datepicker table td > span,
.p-datepicker table th > span {
  width: 2rem !important;
  height: 2rem !important;
  font-size: 14px;
}

.p-datepicker table td,
.p-datepicker table th {
  padding: 1px !important;
}

.p-datepicker .p-timepicker span {
  font-size: 14px;
}