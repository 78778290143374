.btn {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(172, 172, 172, 0.52);
  box-sizing: border-box;
  border-radius: 5px;
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 34px;
  text-align: center;
  color: #818181;
  padding: 0 18px;
}

.btn:hover {
  border: 1px solid rgba(27, 27, 27, 0.52);
  color: rgb(84 84 84);
  cursor: pointer;
}

.btn_login {
  display: flex;
  align-items: center;
  border: 1px solid rgba(172, 172, 172, 0.52);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin-right: 16px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.btn_login img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-right: 2px;
  border-radius: 18px;
}

.mobile_menu__item{
  display: block;
  height: 32px;
  margin: 2px;
  padding: 2px;
  font-family: 'MontserratRegular';
}

.authorization {
  max-height: 440px !important;
}

.authWindow {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 557px;
}

.authWindow div[class="p-dialog-header"] {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.authWindow div[class="p-dialog-content"] {
  padding: 1.5rem;
  height: 100vh;
}

.authWindow div[class="p-dialog-footer"] {
  background: linear-gradient( 0deg, rgba(58, 51, 53, 0.04), rgba(58, 51, 53, 0.04) ), #126567;
  padding: 1rem;
  font-family: "MontserratRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #e9ecef;
}

.authWindow div[class="p-dialog-footer"] {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.authWindow div[class="p-dialog-footer"] span {
  cursor: default;
}
.authWindow div[class="p-dialog-footer"] span b:hover {
  cursor: pointer;
  text-decoration: underline;
}

.completedWindow div[class="p-dialog-content"] {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.form_label {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.1px;
  color: #908e8e;
  padding-left: 0.75rem;
}

.form_label_red {
  color: #eb5e55;
}

.form_button {
  width: 100%;
  background: #ffa307;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: white;
  font-family: "MontserratBold";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.1px;
}

.other_btn {
  cursor: pointer;
  width: 100%;
  padding: 18px 16px;
  background: #ffffff;
  border: 1px solid #ede8e9;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "MontserratBold";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #908e8e;
}

.other_btn span {
  width: calc(100% - 24px);
}

.reload_pass{
  display: inline-block;
  text-align: right;
  color: #ff515b;
  font-family: 'MontserratBold';
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.1px;
}

.content_text_h2,
.content_text_h1 {
  font-family: "MontserratBold";
  font-style: normal;
  font-weight: 800;
  line-height: 18px;
  color: #3a3335;
}

.content_text_h1 {
  font-size: 34px;
}

.content_text_h2 {
  font-size: 18px;
}

.content_text {
  font-family: "MontserratRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.1px;
  color: #908e8e;
}

.mobile_menu_avatar{
  border-radius: 50%;
  width: 64px;
  height: 64px;
  object-fit: cover;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.mobile_menu_value{
  /* color: #fff; */
  font-size: 16px;
  font-family: var(--font-family-bold)
}

@media screen and (min-width: 576px) {
  .authWindow {
    height: auto;
    max-height: 90%;
  }

  .authWindow div[class="p-dialog-header"] {
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }

  .authWindow div[class="p-dialog-content"] {
    height: auto;
  }

  .authWindow div[class="p-dialog-footer"] {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  .completedWindow div[class="p-dialog-content"] {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
}

.footer_form b {
  font-size: 16px;
  color: #ffa307;
  text-decoration: underline;
}

.typeAccount div[role="button"]{
  width: 50%;
  line-height: 42px;
}

@media screen and (min-width: 992px) {
  .btn {
    font-size: 14px;
  }
}
