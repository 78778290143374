.galleria-demo .custom-galleria.fullscreen {
  display: flex;
  flex-direction: column;
}
.galleria-demo .custom-galleria.fullscreen .p-galleria-content {
  flex-grow: 1;
  justify-content: center;
}
.galleria-demo .custom-galleria .p-galleria-content {
  position: relative;
}
.galleria-demo .custom-galleria .p-galleria-thumbnail-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.galleria-demo .custom-galleria .p-galleria-thumbnail-items-container {
  width: 100%;
}
.galleria-demo .custom-galleria .custom-galleria-footer {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  color: #ffffff;
}
.galleria-demo .custom-galleria .custom-galleria-footer > button {
  background-color: transparent;
  color: #ffffff;
  border: 0 none;
  border-radius: 0;
  margin: 0.2rem 0;
}
.galleria-demo .custom-galleria .custom-galleria-footer > button.fullscreen-button {
  margin-left: auto;
}
.galleria-demo .custom-galleria .custom-galleria-footer > button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.galleria-demo .custom-galleria .title-container > span {
  font-size: 0.9rem;
  padding-left: 0.829rem;
}
.galleria-demo .custom-galleria .title-container > span.title {
  font-weight: bold;
}

.gallery-images-list{
  position: relative;
}

.gallery-images-list button{
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(255, 255, 255, 0.829) !important;
}

.gallery-images-list button span{
  font-size: 18px;
}

.p-scrollpanel p {
  padding: .5rem;
  line-height: 1.5;
  margin: 0;
}

.p-scrollpanel.gallery-scroll .p-scrollpanel-wrapper {
  border-right: 10px solid var(--surface-b);
  border-bottom: 10px solid var(--surface-b);
}

.p-scrollpanel.gallery-scroll .p-scrollpanel-bar {
  background-color: var(--surface-d);
  border-radius: 0;
  opacity: 1;
  transition: background-color .2s;
}

.p-tabview .p-tabview-panels {
  padding: 1rem 0rem !important;
}

.swiper-wrapper {
  padding: 4px 0;
}

.video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {
  width: 100%;
  height: 100%;
  padding-top: 0px !important;
}

.video-react .video-react-video {
  position: relative;
}