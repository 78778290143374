.float_icon{
  left: .85rem !important;
}

.search_input{
  border-radius: 6px 0px 0px 6px !important;
  border: 1px solid var(--color-ground) !important;
  border-right: 0px !important;
  width: 100%;
  padding-left: 40px;
}

.search_btn{
  text-align: center !important;
  padding: 12px 16px !important;
  overflow: inherit !important;
  border-radius: 0px 6px 6px 0px !important;
  background: var(--color-ground) !important;
  border-color: var(--color-ground) !important;
}

[class~="p-button"].search_btn{
  border-radius: 0px 6px 6px 0px !important;
}

.filter .search_input:focus-visible {
  box-shadow: 0px 0px 0px 0px !important;
}

.filter .search_btn:focus {
  box-shadow: 0px 0px 0px 0px !important;
}

.input_group, .float_label {
  display: flex !important;
  align-items: stretch;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {

}

@media screen and (min-width: 1200px) {

}