.status-tag {
  border-radius: 2px;
  font-weight: 700 !important;
  font-size: 12px !important;
  letter-spacing: .3px;
  max-width: 190px;
  padding: 0.2rem 1rem !important;
}

.status-tag--primary{
  background-color: #b3e5fc;
  color: #23547b;
}

.status-tag--secondary{
  background-color: #CBD5E1;
  color: #1c2127;
}

.status-tag--success{
  background-color: #c8e6c9;
  color: #256029;
}

.status-tag--info{
  background-color: #93C5FD;
  color: #27486e;
}

.status-tag--warning{
  background-color: #feedaf;
  color: #8a5340;
}

.status-tag--help{
  background-color: #eccfff;
  color: #694382;
}

.status-tag--danger{
  background-color: #ffcdd2;
  color: #c63737;
}

.table-column--action.p-button-icon-only {
  border-radius: 50%;
  border: 1px solid #209063;
  background: none;
  color: #209063;
  width: 32px !important;
  height: 32px !important;
}
.table-column--action.p-button:focus {
  box-shadow: 0 0 0 0.2rem #b7e0b8 !important;
}