.windowItemViewer {
  margin: 0;
  width: 100%;
  max-height: 100%;
  height: 100%;
  position: relative;
}
.windowItemViewer.p-dialog > .p-dialog-header {
  background-color: #00000000 !important;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 7px !important;
  z-index: 20000;
  border-bottom: 0px solid !important;
}

.windowItemViewer .p-dialog-header .p-dialog-header-icon {
  color: #495057 !important;
  border-color: transparent !important;
  background: #e9ecef !important;
}

.windowItemViewer > .p-dialog-content {
  background-color: #000000de !important;
  /* padding: 0.25rem !important; */
  padding: 5rem 1.25rem !important;
  height: 100% !important;
}
.ItemViewer-gallery--slide div {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: white !important;
}

.style-swiper span[class*="swiper-pagination-bullet"] {
  width: 15px;
  height: 15px;
  background-color: #fff;
  opacity: .4;
}

.style-swiper span[class*="swiper-pagination-bullet-active"] {
  opacity: 1;
}