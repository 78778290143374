.dishes-category-name{
  font-family: MontserratRegular;
  line-height: 24px;
  margin: 0px;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
}

.dishes-grid-item-content .p-scrollpanel.custombar .p-scrollpanel-bar {
  width: 5px;
  background-color: rgba(0,0,0,.5);
  border-radius: 10px;
  opacity: 1;
  transition: background-color .2s;
}

.dishes-category-icon{
  
}

.dishes-gallery--slide {
  max-width: 240px;
  padding-bottom: 10px;
}

.dishes-grid-item{
  max-width: 240px;
  line-height: 16px;
  margin: 0 2px;
  box-shadow: 0px 1px 5px #ccc;
  border-radius: 5px;
}

.grid-item-img{
  border-radius: 4px;
  width: 66px;
  height: 66px;
  background-size: cover;
  cursor: zoom-in;
  padding: 0.25rem;
}

.dishes-grid-item-top{
  padding: .5rem;
}

.dishes-grid-item-content{
  display: flex;
  overflow: hidden;
  flex: 0 1 auto;
  flex-direction: column;
  /* justify-content: space-between; */
  box-sizing: border-box;
  height: 180px;
  padding: 16px 10px;
  text-align: left;
}

.dishes-name{
  font-weight: bold;
  font-size: 12px;
}

.dishes-description{
  /* margin: 0.15rem 0px; */
  color: #999;
  font-size: 12px;
}

.block-text-mob {
  max-width: 180px;
}

.menu-custom-tab .p-tabview-nav-container{
  position: sticky !important;
  top: 40px !important;
}

.nav-menu-dishes nav{
  display: flex;
  overflow-x: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  top: 112px !important;
}

.nav-menu-dishes nav button{
  background-color: white;
  color: #495057;
  border-radius: 16px;
  padding: 8px 0.75rem;
  margin: 0 0.1rem;
  line-height: 9px;
  border: none;
  white-space: nowrap;
}

.dishes-menu-mobile {
  /* position-anchor: 50% 50%; */
  padding-top: 113px !important;
  margin-top: -102px;
}

.dishes-description-desktop{
  text-align: start;
  color: #9e9e9e;
}

@media screen and (min-width: 576px) {
  .dishes-name{
    font-size: 16px;
  }
  .block-text-mob {
    max-width: initial;
  }
}

@media screen and (min-width: 768px) {
  .nav-menu-dishes nav{
    top: 92px !important;
  }
  
}