.main{
  width: 100%;
  padding: 0px 12px;
  margin: 8px 0px;
  position: relative;
}

.logotype{
  position: absolute;
  top: 16px;
  left: 16px;
}

.docs{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.docs_link{
  font-family: var(--font-family-bold);
  text-align: right;
  color: black;
  text-decoration: underline;
  display: inline-block;
  width: 100%;
  padding: 0px 2px;
  font-size: 12px;
  margin: 8px 0px;
}

.info_text{
  font-family: var(--font-family-regular);
  font-size: 10px;
  margin: 8px 0;
}

.soc_link{
  color: black;
  font-size: 28px;
  margin: 4px 5px;
  display: flex;
  align-items: center;
}

.soc_link span{
  font-size: 14px;
  font-family: var(--font-family-regular);
  margin-left: 12px;
}

.copyright{
  font-family: var(--font-family-regular);
  font-size: 10px;
}

.separator{
  margin: 0 auto;
}

.add_info{
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5rem 0rem 0.5rem;
}

.add_info a{
  position: relative;
  color: #333;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  /* text-decoration: underline; */
}

.add_info .review{
  margin-left: 20px;
}

.add_info .review::before{
  content: '|';
  text-decoration: none;
  position: absolute;
  left: -12px;
  font-size: 17px;
  line-height: 17px;
}

.add_info .info_icon{
  color: #333;
  font-size: 14px;
  line-height: 19px;
}

@media screen and (min-width: 768px) {
  .main{
    padding: 0px 24px;
  }

  .docs{
    justify-content: flex-start;
    margin-left: 104px;
  }
  
  .docs_link{
    font-size: 14px;
    width: auto;
    margin: 7px 10px;
  }

  .soc_link{
    font-size: 22px;
    margin: 5px;
  }

  .info_text{
    font-size: 12px;
  }

  .separator {
    width: 90%;
    margin-bottom: 24px;
  }

  .logotype{
    top: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .main{
    padding: 24px 72px;
  }

  .docs{
    margin-left: 104px;
  }
  
  .docs_link{
    font-size: 16px;
  }

  .soc_link{
    font-size: 26px;
    margin: 5px;
  }

  .info_text{
    font-size: 14px;
  }

  .main .block_info{
    margin-top: 38px !important;
  }

  .separator {
    width: 90%;
    position: absolute;
    left: 57px;
    top: 0px;
  }

  .logotype{
    top: 54px;
    left: 44px;
    width: 124px;
    height: 60px;
  }
}