.account-main {
  min-height: calc(100vh - 75px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.account-page {
  text-align: start;
  padding: 0 .5rem;
  min-height: calc(100vh - 180px);
}

.account-avatar{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 18px;
  background-color: #efefef;
  padding: 0.25rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.account-subscribe{
  min-height: calc(100vh - 350px);
  max-width: 1024px;
  display: flex;
  margin: 0 auto;
}

.account-content {
  /* background-color: rgb(255, 255, 255); */
}
.account-content .p-dataview .p-dataview-content,
.account-content .p-tabview .p-tabview-panels{
  background: #00000000;
}

.account-header h1 {
  color: #3B8F12;
  margin: 0px;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  font-family: 'MontserratBold';
  font-size: 28px;
}

.account-border {
  border: 5px solid #3B8F12;
}

.account-submit-btn {
  width: 100%;
  background: #ffa307;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: white;
  font-family: "MontserratBold";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.1px;
}

.account-submit-btn:hover {
  background-color: #ed9501;
}

.account-submit {
  background: #ffa307 !important;
  border-radius: 10px !important;
  border: none !important;
  cursor: pointer !important;
  color: white !important;
  font-family: "MontserratBold" !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 34px !important;
  text-align: center !important;
  letter-spacing: -0.1px !important;
}

.account-submit.radius-0 {
  border-radius: 0px 0px 5px 5px !important;
}

.account-submit:hover {
  background-color: #ed9501 !important;
}

.account-form-label {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.1px;
  color: #000;
  padding-left: 0.75rem;
}

.image-body{
  width: 80px;
  border-radius: 5px;
}

.table-menu--setting .p-menuitem-text{
  font-size: 12px;
}

.table-menu--setting .p-menuitem-icon{
  width: 1rem;
  font-size: 14px;
}

.restaurant-grid-item-content img {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

.restaurant-list-item {
  display: flex;
  padding: 0.5rem;
  background-color: white;
  margin-bottom: 0.25rem
}

.restaurant-list-item img{
  width: 87px;
  height: 87px;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

.receipt-list-item {
  display: flex;
  padding: 0.5rem;
}

.receipt-list-item img{
  width: 84px;
  height: 84px;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

.restaurant-list-detail{
  font-size: 12px;
  width: 100%;
  font-family: 'MontserratRegular';
  padding: 1rem;
}

.receipt-list-detail{
  font-size: 12px;
  width: 100%;
  font-family: 'MontserratRegular';
  padding: .5rem;
}

.receipt-type{
  font-size: 14px;
  font-family: 'MontserratBlack';
}

.restaurant-name {
  font-size: 16px;
  font-family: 'MontserratBlack';
}

.restaurant-list-action{
  display: flex;
  justify-content: flex-end;
  min-width: 40px;
  height: 40px;
  margin-top: 1rem;
}

@media screen and (min-width: 768px) {
  .account-avatar{
    width: 200px;
    height: 200px;
  }
  .account-page {
    padding: 0 2rem;
  }
  .account-page {
    padding: 0 2rem;
  }
  
  .restaurant-list-item img{
    width: 100px;
    height: auto;
    border-radius: 5px;
  }

  .receipt img{
    width: 116px;
  }
  
  .restaurant-list-detail{
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
  
  .restaurant-name {
    font-size: 18px;
  }

  .account-submit {
    font-size: 14px !important;
  }
}
