.eighteenPlus,
.eighteenPlusText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  margin: 0px 3px;
}
.eighteenPlus {
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #000;
  padding: 9px 5px;
}
