.geolinkSpan {
  position: absolute;
  left: 11px;
  z-index: 0;
}

.ymaps {
  color: rgba(0, 0, 0, 0) !important;
  white-space: pre-wrap !important;
}

.ymaps::before {
  width: 24px !important;
  left: -25px !important;
  top: -4px !important;
  background-position: top !important;
  opacity: 0 !important;
}

.ymaps::after {
  border-bottom: 0px dashed !important;
}
