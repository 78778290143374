@-webkit-keyframes shiny-btn1 {
  0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
  80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
  81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
  100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
}

.p-checkbox.checkbox-start:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #000000 !important;
  background: #ffffff !important;
}

.p-checkbox.checkbox-start .p-checkbox-box.p-highlight {
  border-color: #000000 !important;
  background: #ffffff !important;
}

.p-checkbox.checkbox-start .p-checkbox-box .p-checkbox-icon {
  color: #000000;
  font-weight: 800;
}

.start-page-container{
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.btn-blink:before {
  position: absolute;
  content: '';
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 3s ease-in-out infinite;
}

.start-page-header, .start-page-access{
  border-radius: 17px;
  background-color: #fdfcfc;
}

.start-page-header .button-input{
  background: none;
  border: none;
  color: #04bfc3;
  border-radius: 5px;
  box-shadow: 1px 1px 8px -4px #3d3d3dd4;
  height: 2.5rem;
  width: 2.5rem;
  padding: 6px;
}

.start-page-header .button-input:enabled:hover, 
.start-page-header .button-input:not(button):not(a):not(.p-disabled):hover {
  color: #04bfc3;
  background: none;
}

.header-description {
  /* text-transform: uppercase; */
  font-size: 21px;
  /* padding: 0rem; */
  /* text-shadow: 3px 3px 0px #00f5fa; */
  /* max-width: 880px; */
  margin: 0 auto;
  /* word-spacing: 14px; */
  /* background-color: #00f5fa; */
  /* border-radius: 5px; */
  width: 100%;
}

.header-description-head{
  /* text-shadow: 1px 1px 0px #00f5fa;
  letter-spacing: -3px; */
}

.header-description-head strong{
  font-weight: 800;
  letter-spacing: 2px;
}

.start-page-access{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
  box-shadow: 0px 0px 5px -2px black;
}

.header-description-caps{
  font-size: 2rem;
  line-height: 30px;
  text-shadow: 1px 1px 0px #00f5fa;
  justify-content: center;
}

.start-page-filter{
  /* border: 4px solid #00f5fa; */
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* height: calc(100vh - 200px); */
}

.start-filter{
  cursor: pointer;
}

.start-filter-image{
  width: 100%;
  height: 30vw;
  max-height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px; 
  border: 1px solid silver;
  box-shadow: 
    3px 3px 5px 5px rgb(0 0 0 / 20%), 
    inset 2px 2px 5px 3px rgb(255 255 255 / 64%);
}

.start-filter-name{
  display: flex;
  font-weight: bold;
  line-height: 24px;
  font-size: 14px;
  text-transform: lowercase;
  border-radius: 50px;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: 3px solid;
}
.field-checkbox{
  font-family: MontserratRegular;
  font-size: small;
}

.location-window{
  max-width: 650px;
}

.location-window.p-dialog .p-dialog-content{
  padding-bottom: 1rem;
}

.location-window .p-dialog-content .location-content p{
  text-align: center;
  font-size: 16px;
  font-family: 'MontserratRegular';
}

.location-window .location-checkbox-label {
  font-family: 'MontserratRegular';
  font-size: small;
  user-select: none;
  cursor: pointer;
}

.start-filter-name.color_orange:hover, .start-filter-name.color_pink:hover, .start-filter-name.color_blue:hover{
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
}

.start-filter-name.color_pink:hover{
  background-color: rgb(236 98 106);
}

.start-filter-name.color_orange:hover{
  background-color: rgb(190 104 50);
}

.start-filter-name.color_blue:hover{
  background-color: rgb(4 191 195);
}

.start-filter-name.color_pink{
  border-color: rgb(236 98 106);
  color:rgb(236 98 106);
}

.start-filter-name.color_orange{
  border-color: rgb(190 104 50);
  color: rgb(190 104 50);
}

.start-filter-name.color_blue{
  border-color: rgb(4 191 195);
  color: rgb(4 191 195);
}

@media screen and (min-width: 768px) {
  .header-description-head, .header-description-caps {
    /* text-shadow: 3px 3px 0px #00f5fa; */
  }

  .header-description{
    font-size: 30px;
    padding: 0rem 1rem .5rem;
  }

  .start-page-filter{
    /* border: 8px solid #00f5fa; */
    /* height: calc(100vh - 215px); */
  }

  .start-filter-name{
    font-size: large;
    line-height: 32px;
  }

  .start-page-access{
    font-size: medium;
  }

  .header-description-caps{
    font-size: 4.7rem;
    line-height: 80px;
  }

}

@media screen and (min-width: 992px) {
  .header-description-caps{
    font-size: 6.5rem;
  }

  .header-description {
    word-spacing: 30px;
  }

  .header-description{
    font-size: 48px;
  }
}