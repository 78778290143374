@media screen and (min-width: 576px) {

  .win-dialog--all {
    height: auto;
    max-height: 90%;
  }

  .win-dialog--all div[class="p-dialog-header"] {
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }

  .win-dialog--all div[class="p-dialog-content"] {
    height: auto;
  }

  .win-dialog--all.win-no-footer div[class="p-dialog-content"] {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  .win-dialog--all div[class="p-dialog-footer"] {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  
  .horizontal-banners div {
    height: 113px;
  }

  .header {
    height: 118px;
  }
  .header-logo {
    width: 180px;
    padding: 21px 8px;
    margin-top: 21px;
  }
  .c-sm-12 {
    width: 100% !important;
  }
  .filters label {
    font-size: 16px;
  }
  .restaurant-title h1,
  .news-title h1 {
    line-height: 67px;
  }
  .restaurant-title h1 {
    margin-bottom: -11px;
  }
  .title-h {
    font-size: 7vw;
  }
  .footer-logo {
    width: 254px;
  }
}
@media screen and (min-width: 768px) {
  .logotype-3raza{
    width: 100px;
    height: 48px;
  }
  
  .sides-border {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .horizontal-banners div {
    height: 123px;
  }

  .footer-logo {
    width: 16rem;
  }
  .c-col-6 {
    width: 50% !important;
  }
  .filters label {
    font-size: 18px;
  }
  .title-h {
    font-size: 5vw;
  }
  .footer-contact a {
    font-size: 24px;
  }
  .filters-item {
    font-size: 12px;
  }
  .main-filters {
    margin: 40px 0px 100px;
  }

  .dialog-footer--btn {
    width: 50% !important;
  }

  .p-dialog .p-dialog-header {
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }
  
  .p-dialog .p-dialog-footer {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  .window-border-bottom.p-dialog .p-dialog-content {
    border-radius: 0px 0px 8px 8px !important;
  }

  .p-dialog{
    max-height: 90%;
  }
}
@media screen and (min-width: 992px) {
  .App > .sides-border {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .filters-item {
    font-size: 14px;
  }
  .header-logo {
    width: 234px;
  }
  .c-col-6 {
    width: 50% !important;
  }
  .side-panel-geolocation span {
    font-size: 17px;
  }
  .title-h {
    font-size: 4vw;
  }

  .dialog-footer--btn {
    width: 33% !important;
  }
}
@media screen and (min-width: 1200px) {
  .App {
    background-position: calc(0% - 105px) 88px, calc(100% + 40px) 65px;
    background-size: 296px, 156px;
  }
  .horizontal-banners div {
    height: 143px;
  }
  .c-col-6 {
    width: 50% !important;
  }
  .side-panel-geolocation span {
    font-size: 18px;
  }
  .title-h {
    font-size: 3vw;
  }
  .side-panel {
    min-width: 250px;
  }
  .main-filters {
    margin: 100px 0px 100px;
  }
}

@media screen and (min-width: 1440px) {
  .c-main-xxl {
    width: 85% !important;
  }
}

@media screen and (min-width: 1620px) {
  .c-xxl-20 {
    width: 20% !important;
  }
}
