.address {
  display: flex;
}

.text {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  /* color: #000000; */
}

.text span {
  font-size: 16px;
  margin-left: 0rem;
  text-align: left;
}

.text:hover {
  /* text-decoration: underline; */
}

@media screen and (min-width: 768px) {
  .text {
    font-size: 10px;
  }
}
