.main {
  position: fixed;
  left: -1px;
  top: 220px;
  z-index: 3;
}

.main button {
  background-color: #ff9a11;
  border-color: #ff9a11;
  line-height: 52px;
  border-radius: 0px 5px 5px 0px;
  width: 46px !important;
  height: 46px;
  cursor: pointer;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  transition: 0.3s;
  transform: scale(1);
}

.main button:hover {
  background-color: #ff9a11 !important;
  border-color: #ff9a11 !important;
  transition: 0.3s;
  transform: scale(1.1);
}

.main button span{
  font-size: 1.4rem;
  font-weight: bold;
}